import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import Perfils from '../components/Perfil';
import SeoPage from '../components/SEO/SeoPage';

const Perfil = () => {
  const [userContent, setUserContent] = useState('unknown');
  const [popularContent, setPopularContent] = useState([]);

  return (
    <Layout>
      <SeoPage title="Your Profile Page" />
    </Layout>
  );
};

export default Perfil;
